<template>
    <!-- Begin Hero Area -->
    <!-- data-bg-image="images/hero/bg/2-1-1920x950.jpg"  -->
    <div class="hero-area hero-bg hero-style-2" data-bg-image="" :style="{backgroundImage: `url(${ bgImage })`}">
        <div class="container hero-container">
            <div class="hero-item">
                <div class="hero-content white-text">
                    <h1 class="title">Yendi Play</h1>
                    <p class="desc">Découvrez votre musique préférée comme jamais auparavant ! Téléchargez notre application dès maintenant pour retrouver instantanément vos artistes favoris, explorer de nouvelles pépites musicales et vivre une expérience auditive exceptionnelle. Plongez dans un monde de mélodies et de découvertes, tout au bout de vos doigts. </p>
                    <div class="banner-btn-wrap">
                                <router-link class="banner-btn" to="/contact">
                                    <img src="images/button/3-1-210x70.png" alt="Button">
                                </router-link>
                                <router-link class="banner-btn" to="/contact">
                                    <img src="images/button/3-2-210x70.png" alt="Button">
                                </router-link>
                            </div>
                </div>
                <div class="hero-img">
                    <img src="images/hero/inner-img/2-1-461x1001.png" alt="Hero Image">
                </div>
            </div>
        </div>
        <span class="hero-sticker"></span>
    </div>
    <!-- Hero Area End Here -->
</template>

<script>
import Lightgallery from 'lightgallery/vue'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'

export default {
  name: 'App',
  components: {
    Lightgallery
  },
  data () {
    return {
      plugins: [lgZoom, lgVideo],
      bgImage: 'images/hero/bg/2-1-1920x950.jpg'
    }
  }
}
</script>
<style lang="css">
    @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
    @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
    @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
    .gallery-item {
    cursor: pointer;
    }
</style>
