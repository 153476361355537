<template>
    <!-- Begin Banner Area -->
    <div class="banner-area banner-style-4">
        <div class="container banner-container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="banner-img">
                        <img src="images/banner/4-1-400x722.png" alt="Banner">
                    </div>
                </div>
                <div class="col-lg-7 align-self-center">
                    <div class="banner-content">
                        <span class="banner-category">Easy Manageable</span>
                        <h2 class="banner-title">You can build your body with <span>extbot.</span></h2>
                        <p class="banner-desc">That necessitates a robust ecommerce platform that optimizes your store & products</p>
                        <p class="banner-desc mb-0">That necessitates a robust ecommerce platform that optimizes your store & products</p>
                        <div class="btn-wrap">
                            <router-link class="btn btn-custom-size extbot-primary-2 blackcurrant-hover" to="/contact">Download Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner Area End Here -->
</template>
