<template>
    <!-- Begin Main Header Area -->
    <header class="header">
        <div class="header-area header-position-absolute header-sticky header-style-2" :class="{'is-active': isSticky}">
            <div class="container header-container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-2 col-6">
                        <router-link class="header-logo" to="/home-2">
                            <img class="primary-img" src="images/logo/logo.png" width="60" alt="Header Logo">
                            <img class="sticky-img" src="images/logo/logo.png" width="50" alt="Header Logo">
                        </router-link>
                    </div>
                    <div class="col-xl-9 col-lg-10 col-6">
                        <div class="header-with-btn">
                            <div class="header-menu d-none d-lg-block">
                                <nav class="header-menu-nav onepage-nav">
                                    <ul>
                                        <li class="header-drop-holder">
                                            <router-link to="/">Home</router-link>
                                        </li>
                                        <li class="active">
                                            <a href="#feature">Fonctionalité</a>
                                        </li>
                                        <li>
                                            <a href="#testimonial">A propos</a>
                                        </li>
                                      
                                        <li>
                                            <router-link to="/contact">Contact</router-link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <ul class="header-right">
                                
                                <li class="d-flex d-lg-none">
                                    <button class="btn p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
                                        <i class="icofont-navigation-menu"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions">
            <div class="offcanvas-header">
                <router-link to="/home-2" class="header-logo">
                    <img src="images/logo/logo.png" width="40" alt="Header Logo">
                </router-link>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="header-offcanvas-menu">
                    <nav class="header-offcanvas-nav onepage-offcanvas-nav">
                        <ul>
                            <li>
                                <a href="#feature">Accueil</a>
                            </li>
                            <li>
                                <a href="#feature">Fonctionnalite</a>
                            </li>
                            <li>
                                <a href="#testimonial">A propos</a>
                            </li>
                            <li>
                                <router-link to="/contact">Contact</router-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- Main Header Area End Here -->
</template>
<script>
export default {
  data () {
    return {
      isSticky: false,
      isHidden: false
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY
      if (scrollPos >= 200) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    })
  }
}
</script>

<style lang="scss">
.header{
    &.fixed-top {
        z-index: 99;
    }
}
.header-toggler {
    background: transparent;
    border: 0px;
    color: #fff;
    font-size: 26px;
    &:hover {
        color: #6e5a49;
    }
}
</style>
